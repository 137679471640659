<template>
  <!-- <form @submit="handleSubmit($event)"> -->
  <form @submit.prevent="handleSubmit">
    
      <error v-if='error' :error="error" />
    <h3>Sign Up</h3>
    <div class="form-group">
      <label>First Name</label
      ><input
        type="text"
        class="form-control"
        v-model="first_name"
        placeholder="First Name"
      />
    </div>

    <div class="form-group">
      <label>Last Name</label
      ><input
        type="text"
        class="form-control"
        v-model="last_name"
        placeholder="Last Name"
      />
    </div>

    <div class="form-group">
      <label>Email</label
      ><input
        type="email"
        class="form-control"
        v-model="email"
        placeholder="Email"
      />
    </div>

    <div class="form-group">
      <label>Password</label
      ><input
        type="password"
        class="form-control"
        v-model="password"
        placeholder="Password"
      />
    </div>

    <div class="form-group">
      <label>Confirm Password</label
      ><input
        type="password"
        class="form-control"
        v-model="password_confirm"
        placeholder="Confirm Password"
      />
    </div>

    <button class="btn btn-primary btn-block">Sign Up</button>
  </form>
</template>

<script>
import Error from './Error.vue';
export default {
  name: "Register",
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirm: "",
      error:""
    };
  },
  
  components: {Error},
  methods: {
    // handleSubmit(e){
    //     e.preventDefault();
    //     console.log(['submitted']);
    // }
    handleSubmit() {
      try{

      const data = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        password: this.password,
        password_confirm: this.password_confirm,
      };
      console.log([data]);
      this.$router.push("/login");
      }catch(e){
        this.error='Error occured!';
      }
    },
  },
};
</script>

<style>
</style>